// Here you can add other styles
label {
    margin-bottom: 0;
}

.select {
    &__control {
        min-height: 35px !important;
        border-color: #d8dbe0 !important;
        border-radius: 0.25rem !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
        &--is-focused {
            border-color: #6fc8fc !important;
            outline: 0 !important;
            box-shadow: 0 0 0 0.2rem rgba(5, 147, 230, 0.25) !important
        }
    }
    &__indicator-separator {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }
    &__dropdown-indicator {
        padding: 0 8px !important;
    }
    &__single-value {
        color: #768192 !important;
    }
}

.is-invalid {
    .select {
        &__control {
            border-color: #e55353 !important;
        }
    }
}

.form-control:disabled, .form-control[readonly] {
    background-color: var(--light);
}

.icon-button {
    width: 26px;
    height: 26px;
    background-color: transparent;
    color: var(--primary);
    border: none;
    cursor: pointer;
    outline: none !important;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: var(--primary);
        color: white;
    }
}